import './App.css';
import NavigationBar from './Navbar/NavigationBar';
import QuizCategory from './QuizCategory/QuizCategory';
import AboutUs from './About/About.js';
import CProgramming from './CProgramming/CProgramming';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes instead of Switch
import Main from './CProgramming/main';
import NotFound from './NotFoundPage/NotFoundPage.js';

import SubQuizCategory from './QuizCategory/SubQuizcategory.js';


import Control_Structures_set1 from './CProgramming/Control_Structures/Set1/Control_Structures_set_1';
import Function_set1 from './CProgramming/Functions/Set1/Functions_set_1';
import ContactUs from './ContactUs/Contactus.js';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy.js';
import Disclaimer from './Disclaimer/Disclaimer.js';
import TermsOfServicePage from './Termsofservice/Termsofservice.js';
import TNPSCIVQuizCategory from './Tnpsc/Group4/TNPSCIVQuizCategory.js';
import GS_S1 from './Tnpsc/Group4/Genaral_Studies/set1/GS_S1.js';
import GS_S2 from './Tnpsc/Group4/Genaral_Studies/set2/GS_S2.js';
import GS_S3 from './Tnpsc/Group4/Genaral_Studies/set3/GS_S3.js';
import GS_S4 from './Tnpsc/Group4/Genaral_Studies/set4/GS_S4.js';
import GS_S5 from './Tnpsc/Group4/Genaral_Studies/set5/GS_S5.js';
import GS_S6 from './Tnpsc/Group4/Genaral_Studies/set6/GS_S6.js';
import GS_S7 from './Tnpsc/Group4/Genaral_Studies/set7/GS_S7.js';
import GS_S8 from './Tnpsc/Group4/Genaral_Studies/set8/GS_S8.js';
import TNPSCIVGS from './Tnpsc/Group4/Genaral_Studies/Gs.js';

import Mcqmaster from './Main/mcqmaster.js';
import { GS_S1_data } from './Tnpsc/Group4/Genaral_Studies/set1/GS_S1_data.js';
import Variables_Route from './Routes/Cprogramming/Variables_Route.js';
import Data_Type_Route from './Routes/Cprogramming/Data_Type_Route.js';
import Operators_Route from './Routes/Cprogramming/Operators_Route.js';
import Expressions_Route from './Routes/Cprogramming/Expressions_Route.js';
import Java_Inheritance from './mcq/Programming/Java/Inheritance/Inheritance.js';
import Java_sub_catogery from './mcq/Programming/Java/Java_sub_catogery.js';
import Java_Inheritance_Route from './Routes/Programming/Java/Inheritance_Route.js';
import C_Array_Route from './Routes/Cprogramming/C_Arrays_Route.js';
import C_String_Route from './Routes/Cprogramming/C_String_Route.js';
import C_Constant_Route from './Routes/Cprogramming/C_Constant_Route.js';
import C_Else_If_Route from './Routes/Cprogramming/C_Else_If_Route.js';
import MechanicalEngineering_sub from './QuizCategory/MechanicalEngineering/MechanicalEngineering_sub.js';
import Thermodynamics_Route from './Routes/Mechanical_Engineering/thermal-engineering/Thermodynamics_Route.js';
const App = () => {
  return (
    <Router>
      <Routes> {/* Use Routes instead of Switch */}
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />

        <Route path="/cprogramming" element={<SubQuizCategory/>}/>
        <Route path='/java' element={<Java_sub_catogery/>}/>

        <Route path="/c/*" element={<Variables_Route />} />
        <Route path="/c/datatype/*" element={<Data_Type_Route />} />
        <Route path="/c/operators/*" element={<Operators_Route />} />
        <Route path="/c/expressions/*" element={<Expressions_Route />} />
        <Route path='/c/array/*' element={<C_Array_Route/>}/>
        <Route path='/c/string/*' element={<C_String_Route/>}/>
        <Route path='/c/constant/*' element={<C_Constant_Route/>}/>
        <Route path='/c/else_if/*' element={<C_Else_If_Route/>}/>


        <Route path="/java/inheritance/*" element={<Java_Inheritance_Route />} />



        <Route path="/control-structures-1" element={<Control_Structures_set1 />} />
        <Route path="/functions-1" element={<Function_set1 />} />

        <Route path='/Tnpsc/IV' element={<TNPSCIVQuizCategory />} />
        <Route path='/Tnpsc/IV/gs' element={<TNPSCIVGS />} />
        <Route path='/Tnpsc/IV/gs/s1' element={<GS_S1 />} />
        <Route path='/Tnpsc/IV/gs/s2' element={<GS_S2 />} />
        <Route path='/Tnpsc/IV/gs/s3' element={<GS_S3 />} />
        <Route path='/Tnpsc/IV/gs/s4' element={<GS_S4 />} />
        <Route path='/Tnpsc/IV/gs/s5' element={<GS_S5 />} />
        <Route path='/Tnpsc/IV/gs/s6' element={<GS_S6 />} />
        <Route path='/Tnpsc/IV/gs/s7' element={<GS_S7 />} />
        <Route path='/Tnpsc/IV/gs/s8' element={<GS_S8 />} />



        <Route path="/mechanical-engineering/thermal-engineering" element={<MechanicalEngineering_sub/>}/>
        <Route path="mechanical-engineering/thermal-engineering/thermodynamics/*" element={<Thermodynamics_Route/>}/>

      
        <Route path='/test' element={<Mcqmaster pageTitle="Your Page Title" questionsData={GS_S1_data} />} />





        <Route path="/" element={<QuizCategory />} />
        <Route path="*" element={<NotFound />} />

      </Routes>
    </Router>
  );
};

export default App;
