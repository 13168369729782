export const Thermodynamics_set3 = [
    {
        "question": "What is the main purpose of a heat pump in thermodynamics?",
        "options": [
            "To transfer heat from a colder body to a warmer body.",
            "To generate work by converting heat into mechanical energy.",
            "To store heat energy for later use.",
            "To maintain a constant temperature in a system."
        ],
        "answer": "To transfer heat from a colder body to a warmer body."
    },
    {
        "question": "Which of the following describes an isothermal process?",
        "options": [
            "A process in which the system's temperature remains constant.",
            "A process in which the system's pressure remains constant.",
            "A process in which the volume remains constant.",
            "A process in which there is no heat exchange with the surroundings."
        ],
        "answer": "A process in which the system's temperature remains constant."
    },
    {
        "question": "Which thermodynamic law states that energy cannot be created or destroyed?",
        "options": [
            "First law of thermodynamics",
            "Second law of thermodynamics",
            "Third law of thermodynamics",
            "Zeroth law of thermodynamics"
        ],
        "answer": "First law of thermodynamics"
    },
    {
        "question": "In which type of system does the exchange of energy and matter with the surroundings occur?",
        "options": [
            "Closed system",
            "Open system",
            "Isolated system",
            "Adiabatic system"
        ],
        "answer": "Open system"
    },
    {
        "question": "What does the term 'entropy' represent in thermodynamics?",
        "options": [
            "The total energy available in a system",
            "The measure of disorder or randomness in a system",
            "The energy required to perform work",
            "The amount of heat energy exchanged in a process"
        ],
        "answer": "The measure of disorder or randomness in a system"
    },
    {
        "question": "What is the efficiency of a Carnot engine dependent on?",
        "options": [
            "The temperature of the heat source and heat sink",
            "The speed of the working substance",
            "The type of fuel used",
            "The volume of the working substance"
        ],
        "answer": "The temperature of the heat source and heat sink"
    },
    {
        "question": "What is the equation used to calculate the work done in an isobaric process?",
        "options": [
            "W = P × ΔV",
            "W = m × C × ΔT",
            "W = ΔU + ΔQ",
            "W = P × V"
        ],
        "answer": "W = P × ΔV"
    },
    {
        "question": "Which of the following is a characteristic of an adiabatic process?",
        "options": [
            "Heat exchange occurs with the surroundings.",
            "Temperature remains constant during the process.",
            "There is no heat exchange between the system and surroundings.",
            "Pressure remains constant throughout the process."
        ],
        "answer": "There is no heat exchange between the system and surroundings."
    },
    {
        "question": "Which property is constant during an isentropic process?",
        "options": [
            "Temperature",
            "Pressure",
            "Entropy",
            "Volume"
        ],
        "answer": "Entropy"
    },
    {
        "question": "Which of the following statements about the second law of thermodynamics is true?",
        "options": [
            "It states that entropy of a system can be reduced by performing work.",
            "It states that heat energy can be converted into work without any loss.",
            "It states that entropy of an isolated system always increases.",
            "It states that energy can be created and destroyed."
        ],
        "answer": "It states that entropy of an isolated system always increases."
    }
];
