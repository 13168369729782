export const Thermodynamics_set4 = [
    {
        "question": "Which of the following is true about the third law of thermodynamics?",
        "options": [
            "It states that the entropy of a system approaches a constant value as the temperature approaches absolute zero.",
            "It states that energy can be neither created nor destroyed.",
            "It states that the entropy of an isolated system remains constant.",
            "It defines the relationship between heat and temperature in a system."
        ],
        "answer": "It states that the entropy of a system approaches a constant value as the temperature approaches absolute zero."
    },
    {
        "question": "In a thermodynamic process, when does the system perform work?",
        "options": [
            "When there is a change in the internal energy of the system.",
            "When heat is transferred from the surroundings.",
            "When the volume of the system changes.",
            "When the pressure remains constant."
        ],
        "answer": "When the volume of the system changes."
    },
    {
        "question": "Which of the following represents the relationship between pressure, volume, and temperature in an ideal gas?",
        "options": [
            "Boyle's law",
            "Charles's law",
            "Ideal gas law",
            "Gay-Lussac's law"
        ],
        "answer": "Ideal gas law"
    },
    {
        "question": "What is the main difference between an isochoric process and an isobaric process?",
        "options": [
            "In an isochoric process, volume remains constant; in an isobaric process, pressure remains constant.",
            "In an isochoric process, temperature remains constant; in an isobaric process, volume remains constant.",
            "In an isochoric process, pressure remains constant; in an isobaric process, temperature remains constant.",
            "In an isochoric process, the energy is converted into work; in an isobaric process, energy is used to heat the system."
        ],
        "answer": "In an isochoric process, volume remains constant; in an isobaric process, pressure remains constant."
    },
    {
        "question": "Which of the following statements about a Carnot cycle is true?",
        "options": [
            "It is a reversible cycle that is used to model the most efficient heat engine possible.",
            "It operates at maximum efficiency by using irreversible processes.",
            "It operates at maximum temperature, making it the most efficient thermodynamic cycle.",
            "It is a non-reversible cycle that cannot be used in real-world applications."
        ],
        "answer": "It is a reversible cycle that is used to model the most efficient heat engine possible."
    },
    {
        "question": "What is the primary function of a thermodynamic cycle?",
        "options": [
            "To convert heat into work.",
            "To store energy within a system.",
            "To measure the pressure and volume changes in a system.",
            "To maintain constant entropy during a process."
        ],
        "answer": "To convert heat into work."
    },
    {
        "question": "In a refrigerator, which part is responsible for absorbing heat from the low-temperature reservoir?",
        "options": [
            "Compressor",
            "Condenser",
            "Expansion valve",
            "Evaporator"
        ],
        "answer": "Evaporator"
    },
    {
        "question": "Which of the following terms is used to describe the efficiency of a heat engine?",
        "options": [
            "Thermal efficiency",
            "Entropy change",
            "Work function",
            "Enthalpy change"
        ],
        "answer": "Thermal efficiency"
    },
    {
        "question": "What does a P-V diagram represent in thermodynamics?",
        "options": [
            "The relationship between pressure and volume in a system.",
            "The relationship between temperature and entropy in a system.",
            "The work done by a system during a process.",
            "The change in internal energy during a process."
        ],
        "answer": "The relationship between pressure and volume in a system."
    },
    {
        "question": "In thermodynamics, what does the term 'enthalpy' refer to?",
        "options": [
            "The total heat content of a system.",
            "The energy required to increase the temperature of a system.",
            "The work done by the system during a process.",
            "The heat transferred to the surroundings."
        ],
        "answer": "The total heat content of a system."
    }
];
