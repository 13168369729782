import React from 'react';
import NavigationBar from '../../Navbar/NavigationBar';
import '../subQuizCategory.css'; // Ensure you import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'; // Import the desired icon
import Footer from '../../Footer/Footer';

const MechanicalEngineering_sub = () => {
    const subcategories = [
        { label: 'Thermodynamics Principles', link: '/mechanical-engineering/thermal-engineering/thermodynamics' },
        { label: 'Heat Transfer Mechanisms', link: '/mechanical-engineering/thermal-engineering/heat-transfer' },
        { label: 'Thermal Analysis Techniques', link: '/mechanical-engineering/thermal-engineering/thermal-analysis' },
        { label: 'Thermal Conductivity of Materials', link: '/mechanical-engineering/thermal-engineering/thermal-conductivity' },
        { label: 'Heat Exchangers and Their Applications', link: '/mechanical-engineering/thermal-engineering/heat-exchangers' },
        { label: 'Refrigeration Cycles and Systems', link: '/mechanical-engineering/thermal-engineering/refrigeration' },
        { label: 'Thermal System Design', link: '/mechanical-engineering/thermal-engineering/system-design' },
        { label: 'Combustion Engineering', link: '/mechanical-engineering/thermal-engineering/combustion' },
        { label: 'Energy Conversion Processes', link: '/mechanical-engineering/thermal-engineering/energy-conversion' },
        { label: 'Fluid Mechanics in Thermal Systems', link: '/mechanical-engineering/thermal-engineering/fluid-mechanics' },
        { label: 'Renewable Energy Sources and Thermal Applications', link: '/mechanical-engineering/thermal-engineering/renewable-energy' },
        { label: 'Cooling Systems for Engines', link: '/mechanical-engineering/thermal-engineering/cooling-systems' },
        { label: 'Heat Pumps and Their Applications', link: '/mechanical-engineering/thermal-engineering/heat-pumps' },
        { label: 'Thermal Insulation Materials', link: '/mechanical-engineering/thermal-engineering/insulation-materials' },
        { label: 'Cryogenics and Low-Temperature Applications', link: '/mechanical-engineering/thermal-engineering/cryogenics' },
        { label: 'Computational Fluid Dynamics (CFD) in Thermal Engineering', link: '/mechanical-engineering/thermal-engineering/cfd' },
        { label: 'Heat Recovery Systems', link: '/mechanical-engineering/thermal-engineering/heat-recovery' },
        { label: 'Phase Change Materials', link: '/mechanical-engineering/thermal-engineering/phase-change-materials' },
        { label: 'Thermal Stress Analysis', link: '/mechanical-engineering/thermal-engineering/thermal-stress' },
        { label: 'Experimental Techniques in Thermal Engineering', link: '/mechanical-engineering/thermal-engineering/experimental-techniques' },
        { label: 'Nanotechnology in Heat Transfer', link: '/mechanical-engineering/thermal-engineering/nanotechnology' },
        { label: 'Environmental Impact of Thermal Systems', link: '/mechanical-engineering/thermal-engineering/environmental-impact' },
        { label: 'Thermal Energy Storage Systems', link: '/mechanical-engineering/thermal-engineering/energy-storage' },
        { label: 'Smart Materials for Thermal Applications', link: '/mechanical-engineering/thermal-engineering/smart-materials' },
        { label: 'Thermal Management in Electronics', link: '/mechanical-engineering/thermal-engineering/electronics-management' },
        { label: 'Air Conditioning and Ventilation Systems', link: '/mechanical-engineering/thermal-engineering/ac-ventilation' },
        { label: 'Waste Heat Recovery Technologies', link: '/mechanical-engineering/thermal-engineering/waste-heat' },
        { label: 'Thermal Systems in Aerospace Engineering', link: '/mechanical-engineering/thermal-engineering/aerospace' },
        { label: 'Thermodynamic Cycles for Power Generation', link: '/mechanical-engineering/thermal-engineering/power-cycles' },
        { label: 'Solar Thermal Energy Systems', link: '/mechanical-engineering/thermal-engineering/solar-thermal' },
        { label: 'Bioenergy and Thermal Conversion', link: '/mechanical-engineering/thermal-engineering/bioenergy' },
        { label: 'Heat Transfer in Biological Systems', link: '/mechanical-engineering/thermal-engineering/biological-systems' },
        { label: 'Thermal Design of Buildings', link: '/mechanical-engineering/thermal-engineering/building-design' },
        { label: 'Thermal Analysis of Materials', link: '/mechanical-engineering/thermal-engineering/materials-analysis' },
        { label: 'Advanced Heat Transfer Techniques', link: '/mechanical-engineering/thermal-engineering/advanced-techniques' },
        { label: 'Thermal Process Engineering', link: '/mechanical-engineering/thermal-engineering/process-engineering' },
        { label: 'Thermal System Optimization', link: '/mechanical-engineering/thermal-engineering/system-optimization' },
        { label: 'Non-Newtonian Fluid Heat Transfer', link: '/mechanical-engineering/thermal-engineering/non-newtonian' },
        { label: 'Thermal Fatigue in Materials', link: '/mechanical-engineering/thermal-engineering/thermal-fatigue' },
        { label: 'Heat Transfer in Porous Media', link: '/mechanical-engineering/thermal-engineering/porous-media' },
        { label: 'Thermal Radiation and Its Applications', link: '/mechanical-engineering/thermal-engineering/radiation' },
        { label: 'Heat Transfer Enhancement Techniques', link: '/mechanical-engineering/thermal-engineering/heat-transfer-enhancement' },
        { label: 'Thermal Behavior of Composite Materials', link: '/mechanical-engineering/thermal-engineering/composite-materials' },
        { label: 'Heat Transfer in Microchannels', link: '/mechanical-engineering/thermal-engineering/microchannels' },
        { label: 'Thermal Imaging and Measurement Techniques', link: '/mechanical-engineering/thermal-engineering/thermal-imaging' },
        { label: 'Thermal Management of Batteries', link: '/mechanical-engineering/thermal-engineering/battery-management' },
        { label: 'Thermodynamic Properties of Gases and Liquids', link: '/mechanical-engineering/thermal-engineering/properties' },
        { label: 'Thermal Performance Evaluation of HVAC Systems', link: '/mechanical-engineering/thermal-engineering/hvac-evaluation' },
        { label: 'Thermal Simulation Software', link: '/mechanical-engineering/thermal-engineering/simulation-software' },
        { label: 'Thermal Design and Optimization of Turbines', link: '/mechanical-engineering/thermal-engineering/turbine-design' },
        { label: 'Thermal Effects in Electronics Cooling', link: '/mechanical-engineering/thermal-engineering/electronics-cooling' },
        { label: 'Thermal Management in Data Centers', link: '/mechanical-engineering/thermal-engineering/data-centers' },
        { label: 'Thermal Design for Space Applications', link: '/mechanical-engineering/thermal-engineering/space-design' },
        { label: 'Heat Transfer in Chemical Reactors', link: '/mechanical-engineering/thermal-engineering/chemical-reactors' },
        { label: 'Thermal Analysis of Polymers', link: '/mechanical-engineering/thermal-engineering/polymer-analysis' },
        { label: 'Thermal Management in Automotive Applications', link: '/mechanical-engineering/thermal-engineering/automotive' },
        { label: 'Heat Transfer in Solar Collectors', link: '/mechanical-engineering/thermal-engineering/solar-collectors' },
        { label: 'Thermal Effects in Additive Manufacturing', link: '/mechanical-engineering/thermal-engineering/additive-manufacturing' },
        { label: 'Thermal Performance of Thermal Energy Storage Systems', link: '/mechanical-engineering/thermal-engineering/performance-storage' },
        { label: 'Thermal Properties of Building Materials', link: '/mechanical-engineering/thermal-engineering/building-materials' },
        { label: 'Heat Transfer in Geothermal Systems', link: '/mechanical-engineering/thermal-engineering/geothermal' },
        { label: 'Thermal Effects in Phase Change Processes', link: '/mechanical-engineering/thermal-engineering/phase-change' },
        { label: 'Thermal Properties of Biomaterials', link: '/mechanical-engineering/thermal-engineering/biomaterials' },
        { label: 'Thermal Management of Wind Turbines', link: '/mechanical-engineering/thermal-engineering/wind-turbines' },
        { label: 'Heat Transfer in Industrial Processes', link: '/mechanical-engineering/thermal-engineering/industrial-processes' },
        { label: 'Thermal Processes in Food Engineering', link: '/mechanical-engineering/thermal-engineering/food-engineering' },
        { label: 'Thermal Behavior of Engineered Materials', link: '/mechanical-engineering/thermal-engineering/engineered-materials' },
        { label: 'Heat Transfer in Nuclear Reactors', link: '/mechanical-engineering/thermal-engineering/nuclear-reactors' },
        { label: 'Thermal Applications of Nanofluids', link: '/mechanical-engineering/thermal-engineering/nanofluids' },
        { label: 'Thermal Performance of High-Temperature Materials', link: '/mechanical-engineering/thermal-engineering/high-temperature-materials' },
        { label: 'Thermal Design for Offshore Structures', link: '/mechanical-engineering/thermal-engineering/offshore-structures' },
        { label: 'Heat Transfer in Cryogenic Applications', link: '/mechanical-engineering/thermal-engineering/cryogenic-applications' },
        { label: 'Thermal Effects on Material Properties', link: '/mechanical-engineering/thermal-engineering/material-properties' },
        { label: 'Thermal System Design for Renewable Energy', link: '/mechanical-engineering/thermal-engineering/renewable-system-design' },
        { label: 'Heat Transfer in Vacuum Systems', link: '/mechanical-engineering/thermal-engineering/vacuum-systems' },
        { label: 'Thermal Performance of Bioreactors', link: '/mechanical-engineering/thermal-engineering/bioreactors' },
        { label: 'Thermal Management of Energy Systems', link: '/mechanical-engineering/thermal-engineering/energy-systems' },
        { label: 'Thermal Dynamics of Magnetic Materials', link: '/mechanical-engineering/thermal-engineering/magnetic-materials' },
        { label: 'Heat Transfer in Building Envelopes', link: '/mechanical-engineering/thermal-engineering/building-envelopes' },
        { label: 'Thermal Systems in Oil and Gas Industry', link: '/mechanical-engineering/thermal-engineering/oil-gas-industry' },
        { label: 'Thermal Management in Manufacturing Processes', link: '/mechanical-engineering/thermal-engineering/manufacturing-processes' },
    ];
    
    
    
    return (
        <div>
            <NavigationBar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-9">
                        <div className="row">
                            {/* Map over the subcategories array to generate subcategory cards */}
                            {subcategories.map((subcategory, index) => (
                                <div className="col-6" key={index}>
                                    <a className="card-items" href={subcategory.link}>
                                        <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
                                        {subcategory.label}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="para">
                    <p>Thermodynamics is the branch of physics that studies the relationships between heat, work, and energy within physical systems. It is governed by fundamental laws that describe how energy is transferred and transformed, influencing everything from engines and refrigerators to biological processes and chemical reactions.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MechanicalEngineering_sub;
