export const Thermodynamics_set10 = [
    {
        "question": "Which of the following statements is correct about the first law of thermodynamics?",
        "options": [
            "Energy cannot be created or destroyed, only converted from one form to another.",
            "The total energy of a system always increases.",
            "Energy can be created from nothing, as long as it is converted to heat.",
            "Energy can be destroyed if it is converted into work."
        ],
        "answer": "Energy cannot be created or destroyed, only converted from one form to another."
    },
    {
        "question": "In which process does the internal energy of a system remain constant?",
        "options": [
            "Isothermal process",
            "Adiabatic process",
            "Isochoric process",
            "Isobaric process"
        ],
        "answer": "Isothermal process"
    },
    {
        "question": "Which of the following is the main difference between a heat engine and a refrigerator?",
        "options": [
            "A heat engine absorbs heat from a cold reservoir, while a refrigerator absorbs heat from a hot reservoir.",
            "A heat engine converts heat into work, while a refrigerator does work to transfer heat from a cold to a hot reservoir.",
            "A heat engine operates in the Carnot cycle, while a refrigerator operates in the Rankine cycle.",
            "A heat engine requires an input of work, while a refrigerator does not."
        ],
        "answer": "A heat engine converts heat into work, while a refrigerator does work to transfer heat from a cold to a hot reservoir."
    },
    {
        "question": "What is the significance of the Carnot cycle in thermodynamics?",
        "options": [
            "It is an idealized cycle that defines the maximum efficiency possible for a heat engine.",
            "It is a real-world cycle used in modern power plants.",
            "It describes the behavior of gases under constant pressure.",
            "It is a cycle for refrigerators, not heat engines."
        ],
        "answer": "It is an idealized cycle that defines the maximum efficiency possible for a heat engine."
    },
    {
        "question": "Which of the following describes an adiabatic process?",
        "options": [
            "A process in which heat is exchanged with the surroundings.",
            "A process in which the system absorbs heat but no work is done.",
            "A process in which no heat is exchanged with the surroundings.",
            "A process in which temperature remains constant."
        ],
        "answer": "A process in which no heat is exchanged with the surroundings."
    },
    {
        "question": "In thermodynamics, the term 'enthalpy' refers to the sum of which two properties of a system?",
        "options": [
            "Internal energy and pressure",
            "Internal energy and volume",
            "Heat capacity and pressure",
            "Internal energy and the product of pressure and volume"
        ],
        "answer": "Internal energy and the product of pressure and volume"
    },
    {
        "question": "What does the second law of thermodynamics state about the direction of heat flow?",
        "options": [
            "Heat flows from a colder object to a hotter object.",
            "Heat flows from a hotter object to a colder object.",
            "Heat flow can occur in any direction depending on the system.",
            "Heat flow does not occur in a closed system."
        ],
        "answer": "Heat flows from a hotter object to a colder object."
    },
    {
        "question": "Which of the following processes is a reversible process?",
        "options": [
            "The expansion of an ideal gas in a vacuum.",
            "The melting of ice in a glass of water.",
            "The compression of a gas in a piston with no heat exchange.",
            "The flow of heat from a hot body to a cold body."
        ],
        "answer": "The compression of a gas in a piston with no heat exchange."
    },
    {
        "question": "What is the main assumption in the ideal gas law (PV = nRT)?",
        "options": [
            "The gas particles are large and interact significantly.",
            "The gas particles do not interact and occupy no volume.",
            "The gas particles are infinitely small and are perfectly elastic.",
            "The gas particles occupy a fixed volume."
        ],
        "answer": "The gas particles do not interact and occupy no volume."
    },
    {
        "question": "What is the relationship between pressure and volume in an isothermal process for an ideal gas?",
        "options": [
            "Pressure is directly proportional to volume.",
            "Pressure is inversely proportional to volume.",
            "Pressure remains constant throughout the process.",
            "Pressure and volume are not related."
        ],
        "answer": "Pressure is inversely proportional to volume."
    }
];
