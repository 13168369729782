export const Thermodynamics_set8 = [
    {
        "question": "What is the first law of thermodynamics also known as?",
        "options": [
            "The Law of Energy Conservation",
            "The Law of Entropy",
            "The Law of Thermodynamic Equilibrium",
            "The Law of Heat Transfer"
        ],
        "answer": "The Law of Energy Conservation"
    },
    {
        "question": "In an ideal gas, the internal energy depends only on which factor?",
        "options": [
            "Pressure",
            "Volume",
            "Temperature",
            "Density"
        ],
        "answer": "Temperature"
    },
    {
        "question": "Which of the following describes a reversible process in thermodynamics?",
        "options": [
            "A process where the system and surroundings can return to their initial states.",
            "A process where energy is lost as heat.",
            "A process that always happens in one direction.",
            "A process in which entropy decreases."
        ],
        "answer": "A process where the system and surroundings can return to their initial states."
    },
    {
        "question": "Which of the following gases behaves most ideally under standard conditions?",
        "options": [
            "Oxygen",
            "Carbon dioxide",
            "Helium",
            "Ammonia"
        ],
        "answer": "Helium"
    },
    {
        "question": "What is the main purpose of a Carnot engine?",
        "options": [
            "To increase the temperature of the working fluid.",
            "To maximize the work done by the engine.",
            "To operate as efficiently as possible between two temperatures.",
            "To generate electricity from heat energy."
        ],
        "answer": "To operate as efficiently as possible between two temperatures."
    },
    {
        "question": "Which of the following is a direct consequence of the second law of thermodynamics?",
        "options": [
            "Heat cannot flow spontaneously from a colder body to a hotter body.",
            "The total energy of a system remains constant.",
            "The temperature of an isolated system increases with time.",
            "Work can be completely converted into heat."
        ],
        "answer": "Heat cannot flow spontaneously from a colder body to a hotter body."
    },
    {
        "question": "What does the term 'specific volume' of a substance refer to?",
        "options": [
            "The volume occupied by a unit mass of the substance.",
            "The total volume of the system.",
            "The volume at which the substance is in its gaseous state.",
            "The volume required to increase the pressure of the substance."
        ],
        "answer": "The volume occupied by a unit mass of the substance."
    },
    {
        "question": "Which thermodynamic property is associated with the energy needed to change the temperature of a substance without changing its phase?",
        "options": [
            "Latent heat",
            "Specific heat",
            "Internal energy",
            "Enthalpy"
        ],
        "answer": "Specific heat"
    },
    {
        "question": "In thermodynamics, what is 'adiabatic expansion'?",
        "options": [
            "The process where a gas expands without exchanging heat with its surroundings.",
            "The process where a gas expands and absorbs heat from the surroundings.",
            "The process where the volume of a system remains constant.",
            "The process where a gas is compressed without heat exchange."
        ],
        "answer": "The process where a gas expands without exchanging heat with its surroundings."
    },
    {
        "question": "What is the efficiency of a Carnot engine dependent on?",
        "options": [
            "The pressure of the working fluid",
            "The temperature of the working fluid",
            "The difference in temperatures between the hot and cold reservoirs",
            "The volume of the working fluid"
        ],
        "answer": "The difference in temperatures between the hot and cold reservoirs"
    }
];
