export const Thermodynamics_set1 = [
    {
        "question": "What is the first law of thermodynamics?",
        "options": [
            "Energy can neither be created nor destroyed, only transformed.",
            "Entropy of an isolated system always decreases.",
            "The total energy of an isolated system is constant.",
            "Heat cannot flow from a colder body to a hotter body without work."
        ],
        "answer": "Energy can neither be created nor destroyed, only transformed."
    },
    {
        "question": "Which of the following is an example of an adiabatic process?",
        "options": [
            "Heating a gas at constant volume.",
            "Compressing a gas without heat exchange.",
            "Isothermal expansion of a gas.",
            "Evaporating water in an open container."
        ],
        "answer": "Compressing a gas without heat exchange."
    },
    {
        "question": "What is the unit of entropy in the SI system?",
        "options": [
            "Joules per Kelvin (J/K)",
            "Joules (J)",
            "Kelvin (K)",
            "Joules per meter (J/m)"
        ],
        "answer": "Joules per Kelvin (J/K)"
    },
    {
        "question": "Which thermodynamic process occurs at constant temperature?",
        "options": [
            "Isochoric process",
            "Adiabatic process",
            "Isobaric process",
            "Isothermal process"
        ],
        "answer": "Isothermal process"
    },
    {
        "question": "What does the second law of thermodynamics state about entropy?",
        "options": [
            "Entropy remains constant in any process.",
            "Entropy of an isolated system always decreases.",
            "Entropy of an isolated system always increases.",
            "Entropy can be created but never destroyed."
        ],
        "answer": "Entropy of an isolated system always increases."
    },
    {
        "question": "Which of the following is a result of increasing the temperature of a gas?",
        "options": [
            "The volume of the gas decreases.",
            "The pressure of the gas decreases.",
            "The internal energy of the gas increases.",
            "The density of the gas decreases."
        ],
        "answer": "The internal energy of the gas increases."
    },
    {
        "question": "What is the Carnot efficiency dependent on?",
        "options": [
            "The pressure of the working substance",
            "The temperature difference between the hot and cold reservoirs",
            "The volume of the working substance",
            "The type of gas used"
        ],
        "answer": "The temperature difference between the hot and cold reservoirs"
    },
    {
        "question": "Which of the following is true for an isobaric process?",
        "options": [
            "The pressure of the system remains constant.",
            "The volume of the system remains constant.",
            "The temperature of the system remains constant.",
            "The heat added is zero."
        ],
        "answer": "The pressure of the system remains constant."
    },
    {
        "question": "In a steam engine, the heat engine works based on which principle?",
        "options": [
            "First law of thermodynamics",
            "Second law of thermodynamics",
            "Law of conservation of energy",
            "Zeroth law of thermodynamics"
        ],
        "answer": "Second law of thermodynamics"
    },
    {
        "question": "What is the process in which heat flows from the hot body to the cold body?",
        "options": [
            "Conduction",
            "Convection",
            "Radiation",
            "Thermal equilibrium"
        ],
        "answer": "Conduction"
    }
];
