export const Thermodynamics_set5 = [
    {
        "question": "Which of the following is the correct definition of work done in a thermodynamic process?",
        "options": [
            "The heat transferred to the system.",
            "The energy transferred to the system by mechanical means.",
            "The total change in internal energy of the system.",
            "The energy required to increase the temperature of the system."
        ],
        "answer": "The energy transferred to the system by mechanical means."
    },
    {
        "question": "In an ideal gas, which of the following is true about the relationship between internal energy and temperature?",
        "options": [
            "Internal energy is directly proportional to temperature.",
            "Internal energy is inversely proportional to temperature.",
            "Internal energy is independent of temperature.",
            "Internal energy is only dependent on pressure."
        ],
        "answer": "Internal energy is directly proportional to temperature."
    },
    {
        "question": "What does the term 'isentropic process' refer to?",
        "options": [
            "A process where temperature remains constant.",
            "A process where pressure remains constant.",
            "A process where entropy remains constant.",
            "A process where volume remains constant."
        ],
        "answer": "A process where entropy remains constant."
    },
    {
        "question": "Which of the following describes the concept of 'heat capacity' in thermodynamics?",
        "options": [
            "The amount of heat required to change the temperature of a substance.",
            "The amount of work done by the system.",
            "The energy required to change the volume of a system.",
            "The energy required to change the pressure of a system."
        ],
        "answer": "The amount of heat required to change the temperature of a substance."
    },
    {
        "question": "What is the main characteristic of an adiabatic process?",
        "options": [
            "There is no change in pressure.",
            "There is no exchange of heat with the surroundings.",
            "The system undergoes no change in temperature.",
            "The system does not perform any work."
        ],
        "answer": "There is no exchange of heat with the surroundings."
    },
    {
        "question": "In a Rankine cycle, what happens to the steam after it passes through the turbine?",
        "options": [
            "It enters the condenser.",
            "It is heated in the boiler.",
            "It undergoes compression in the pump.",
            "It expands in the compressor."
        ],
        "answer": "It enters the condenser."
    },
    {
        "question": "Which of the following is the primary cause of energy loss in a real engine compared to an ideal engine?",
        "options": [
            "Frictional losses",
            "Heat transfer to the surroundings",
            "Mechanical work output",
            "Efficiency of the working substance"
        ],
        "answer": "Heat transfer to the surroundings"
    },
    {
        "question": "What does a Joule-Thomson effect describe?",
        "options": [
            "The change in pressure during adiabatic expansion.",
            "The change in volume during isothermal compression.",
            "The change in temperature during expansion or compression of a gas at constant enthalpy.",
            "The relationship between pressure and temperature in an ideal gas."
        ],
        "answer": "The change in temperature during expansion or compression of a gas at constant enthalpy."
    },
    {
        "question": "What is the relationship between temperature and entropy in an irreversible process?",
        "options": [
            "The temperature and entropy both remain constant.",
            "Entropy increases as temperature decreases.",
            "Entropy increases as temperature increases.",
            "Entropy remains constant while temperature increases."
        ],
        "answer": "Entropy increases as temperature increases."
    },
    {
        "question": "Which of the following is the correct statement about the Kelvin-Planck statement of the second law of thermodynamics?",
        "options": [
            "It states that it is impossible to convert all heat energy into work in a cyclic process.",
            "It states that the total energy of an isolated system is constant.",
            "It states that the entropy of an isolated system remains constant.",
            "It states that the energy of the universe decreases over time."
        ],
        "answer": "It states that it is impossible to convert all heat energy into work in a cyclic process."
    }
];
