export const Thermodynamics_set6 = [
    {
        "question": "What is the main purpose of the Carnot cycle in thermodynamics?",
        "options": [
            "To determine the maximum efficiency of a heat engine.",
            "To increase the temperature of a working fluid.",
            "To cool a gas to absolute zero.",
            "To convert all heat energy into mechanical work."
        ],
        "answer": "To determine the maximum efficiency of a heat engine."
    },
    {
        "question": "Which of the following statements is true about the first law of thermodynamics?",
        "options": [
            "Energy can neither be created nor destroyed, only converted from one form to another.",
            "The entropy of an isolated system always decreases.",
            "Heat energy can be fully converted into work.",
            "Work done by a system is always positive."
        ],
        "answer": "Energy can neither be created nor destroyed, only converted from one form to another."
    },
    {
        "question": "Which thermodynamic property is associated with the energy required to break intermolecular bonds in a substance?",
        "options": [
            "Heat of fusion",
            "Heat of vaporization",
            "Enthalpy",
            "Internal energy"
        ],
        "answer": "Heat of vaporization"
    },
    {
        "question": "In a thermodynamic system, what does the term 'enthalpy' represent?",
        "options": [
            "The internal energy of the system.",
            "The energy required to change the temperature of the system.",
            "The total heat content of the system.",
            "The energy required to do work on the surroundings."
        ],
        "answer": "The total heat content of the system."
    },
    {
        "question": "Which of the following processes is characterized by the constant volume of a system?",
        "options": [
            "Isochoric process",
            "Isobaric process",
            "Isoenthalpic process",
            "Isothermal process"
        ],
        "answer": "Isochoric process"
    },
    {
        "question": "What is the efficiency of a Carnot engine operating between a hot reservoir at 500 K and a cold reservoir at 300 K?",
        "options": [
            "40%",
            "60%",
            "20%",
            "80%"
        ],
        "answer": "40%"
    },
    {
        "question": "What does the Clausius-Clapeyron equation relate to in thermodynamics?",
        "options": [
            "The relationship between pressure and temperature during phase transitions.",
            "The energy required to change the internal energy of a system.",
            "The relationship between the temperature and the entropy of a gas.",
            "The work done in an adiabatic process."
        ],
        "answer": "The relationship between pressure and temperature during phase transitions."
    },
    {
        "question": "In thermodynamics, what does the term 'irreversible process' refer to?",
        "options": [
            "A process that can be reversed with no net energy change.",
            "A process that cannot be reversed without a net increase in entropy.",
            "A process where the system reaches equilibrium immediately.",
            "A process that involves no work being done."
        ],
        "answer": "A process that cannot be reversed without a net increase in entropy."
    },
    {
        "question": "Which of the following statements best defines the second law of thermodynamics?",
        "options": [
            "The entropy of an isolated system always increases over time.",
            "Energy can neither be created nor destroyed.",
            "The total energy of a system remains constant.",
            "Work can be fully converted into heat energy."
        ],
        "answer": "The entropy of an isolated system always increases over time."
    },
    {
        "question": "What is the primary function of a heat pump in thermodynamics?",
        "options": [
            "To convert mechanical work into heat energy.",
            "To transfer heat from a cold to a hot reservoir.",
            "To convert heat into mechanical work.",
            "To reduce the temperature of a system."
        ],
        "answer": "To transfer heat from a cold to a hot reservoir."
    }
];
