import React from 'react';
import NavigationBar from '../Navbar/NavigationBar';
import './subQuizCategory.css'; // Ensure you import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'; // Import the desired icon
import Footer from '../Footer/Footer';

const SubQuizCategory = () => {
    const subcategories = [
        { label: 'Variables', link: '/C/variables' },
        { label: 'Data Types', link: '/C/datatype' },
        { label: 'Operators', link: '/C/operators' },
        { label: 'Expressions', link: '/C/expressions' },
        { label: 'Arrays', link: '/C/array' },
        { label: 'String', link: '/C/string' },
        { label: 'Constant', link: '/C/constant' },
        { label: 'IF ELSE', link: '/C/else_if/' },
        // Add more subcategories as needed
    ];
    
    return (
        <div>
            <NavigationBar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-9">
                        <div className="row">
                            {/* Map over the subcategories array to generate subcategory cards */}
                            {subcategories.map((subcategory, index) => (
                                <div className="col-6" key={index}>
                                    <a className="card-items" href={subcategory.link}>
                                        <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
                                        {subcategory.label}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="para">
                    <p>C variables are containers used to store data in a C program. They must be declared before use, specifying the data type. Variables can hold integers, floating-point numbers, characters, and more. Initialization can be done at declaration. Proper understanding and management of variables are crucial for efficient programming in C.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SubQuizCategory;
