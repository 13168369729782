// Data_Type_Route.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MechanicalEngineering_Thermodynamics from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/MechanicalEngineering_Thermodynamics';
import { Thermodynamics_set1 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set1';
import { Thermodynamics_set2 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set2';
import { Thermodynamics_set3 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set3';
import { Thermodynamics_set4 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set4';
import { Thermodynamics_set5 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set5';
import { Thermodynamics_set6 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set6';
import { Thermodynamics_set7 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set7';
import { Thermodynamics_set8 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set8';
import { Thermodynamics_set9 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set9';
import { Thermodynamics_set10 } from '../../../QuizCategory/MechanicalEngineering/Thermodynamics/Data/Thermodynamics_set10';
import Mcqmaster from '../../../Main/mcqmaster';

const Thermodynamics_Route = () => {
  return (
    <Routes>
      <Route path="" element={<MechanicalEngineering_Thermodynamics />} />
      <Route path="1" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 1-10" questionsData={Thermodynamics_set1} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/1" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/2" />} />
      <Route path="2" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 11-20" questionsData={Thermodynamics_set2} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/1" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/3" />} />
      <Route path="3" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 21-30" questionsData={Thermodynamics_set3} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/2" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/4" />} />
      <Route path="4" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 31-40" questionsData={Thermodynamics_set4} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/3" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/5" />} />

      <Route path="5" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 41-50" questionsData={Thermodynamics_set5} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/4" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/6" />} />
      <Route path="6" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 51-60" questionsData={Thermodynamics_set6} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/5" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/7" />} />
      <Route path="7" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 61-70" questionsData={Thermodynamics_set7} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/6" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/8" />} />
      <Route path="8" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 71-80" questionsData={Thermodynamics_set8} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/7" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/9" />} />

      <Route path="9" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 81-90" questionsData={Thermodynamics_set9} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/8" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/10" />} />
      <Route path="10" element={<Mcqmaster pageTitle="Thermodynamics MCQ Questions 91-100" questionsData={Thermodynamics_set10} previouslink="/mechanical-engineering/thermal-engineering/Thermodynamics/9" nextlink="/mechanical-engineering/thermal-engineering/Thermodynamics/1" />} />
    </Routes>
  );
}

export default Thermodynamics_Route;
