export const Thermodynamics_set9 = [
    {
        "question": "What is the main factor that determines the efficiency of a heat engine?",
        "options": [
            "The temperature of the heat source",
            "The volume of the working substance",
            "The specific heat of the working fluid",
            "The temperature difference between the hot and cold reservoirs"
        ],
        "answer": "The temperature difference between the hot and cold reservoirs"
    },
    {
        "question": "Which of the following is true about an isothermal process?",
        "options": [
            "The temperature remains constant during the process.",
            "The pressure remains constant during the process.",
            "The volume remains constant during the process.",
            "The internal energy remains constant during the process."
        ],
        "answer": "The temperature remains constant during the process."
    },
    {
        "question": "What is entropy a measure of in thermodynamics?",
        "options": [
            "Energy in a system",
            "Heat in a system",
            "Disorder or randomness of a system",
            "Work done by a system"
        ],
        "answer": "Disorder or randomness of a system"
    },
    {
        "question": "Which of the following gases approaches ideal gas behavior at high temperatures and low pressures?",
        "options": [
            "Carbon dioxide",
            "Ammonia",
            "Helium",
            "Water vapor"
        ],
        "answer": "Helium"
    },
    {
        "question": "What does the term 'latent heat' refer to in thermodynamics?",
        "options": [
            "The heat required to change the temperature of a substance",
            "The heat required to change the phase of a substance without changing its temperature",
            "The total energy of the system",
            "The energy required to do work"
        ],
        "answer": "The heat required to change the phase of a substance without changing its temperature"
    },
    {
        "question": "What is the main difference between an ideal gas and a real gas?",
        "options": [
            "Ideal gases have no intermolecular forces, while real gases do.",
            "Ideal gases behave like liquids, while real gases behave like solids.",
            "Real gases are more compressible than ideal gases.",
            "Ideal gases have more mass than real gases."
        ],
        "answer": "Ideal gases have no intermolecular forces, while real gases do."
    },
    {
        "question": "Which of the following is an example of an isobaric process?",
        "options": [
            "Heating a gas in a rigid container",
            "Expanding a gas in a cylinder with a movable piston while maintaining constant pressure",
            "Compressing a gas in a fixed volume",
            "Expanding a gas in a container while maintaining a constant temperature"
        ],
        "answer": "Expanding a gas in a cylinder with a movable piston while maintaining constant pressure"
    },
    {
        "question": "What is the unit of specific heat in the SI system?",
        "options": [
            "J/kg·K",
            "J/mol·K",
            "K·m/s²",
            "J/m·K"
        ],
        "answer": "J/kg·K"
    },
    {
        "question": "What is the name of the cycle that describes the ideal operation of a heat engine?",
        "options": [
            "Brayton cycle",
            "Carnot cycle",
            "Rankine cycle",
            "Otto cycle"
        ],
        "answer": "Carnot cycle"
    },
    {
        "question": "Which of the following statements is true about the third law of thermodynamics?",
        "options": [
            "Entropy of a system approaches zero as temperature approaches absolute zero.",
            "Entropy of a system increases with temperature.",
            "The third law states that no work can be done in a thermodynamic process.",
            "Heat cannot be transferred from a cold body to a hot body."
        ],
        "answer": "Entropy of a system approaches zero as temperature approaches absolute zero."
    }
];
