export const Thermodynamics_set7 = [
    {
        "question": "What does the term 'specific heat capacity' of a substance refer to?",
        "options": [
            "The heat required to raise the temperature of a unit mass by one degree.",
            "The total heat energy contained in the substance.",
            "The heat required to change the phase of a substance.",
            "The rate at which a substance absorbs heat."
        ],
        "answer": "The heat required to raise the temperature of a unit mass by one degree."
    },
    {
        "question": "Which of the following is the best description of an adiabatic process?",
        "options": [
            "A process in which heat is added to the system without changing its volume.",
            "A process in which there is no heat exchange with the surroundings.",
            "A process in which the temperature remains constant.",
            "A process in which the pressure and temperature of a gas increase simultaneously."
        ],
        "answer": "A process in which there is no heat exchange with the surroundings."
    },
    {
        "question": "In the context of thermodynamics, what does 'entropy' represent?",
        "options": [
            "The energy available to do work.",
            "The total energy in the system.",
            "The measure of disorder or randomness in a system.",
            "The heat energy transferred during a process."
        ],
        "answer": "The measure of disorder or randomness in a system."
    },
    {
        "question": "Which of the following describes an isothermal process?",
        "options": [
            "A process where the volume remains constant.",
            "A process where the temperature remains constant.",
            "A process where no heat is transferred to the system.",
            "A process where the system does work on the surroundings."
        ],
        "answer": "A process where the temperature remains constant."
    },
    {
        "question": "In a thermodynamic process, what does the term 'work' refer to?",
        "options": [
            "Energy transferred as heat.",
            "Energy transferred through the boundary of the system by mechanical means.",
            "The change in internal energy of the system.",
            "The heat required to change the temperature of a substance."
        ],
        "answer": "Energy transferred through the boundary of the system by mechanical means."
    },
    {
        "question": "Which of the following best describes a cyclic process in thermodynamics?",
        "options": [
            "A process where the system undergoes a sequence of changes and returns to its original state.",
            "A process where the volume of a system remains constant.",
            "A process where the internal energy of the system remains constant.",
            "A process where the pressure and temperature are always constant."
        ],
        "answer": "A process where the system undergoes a sequence of changes and returns to its original state."
    },
    {
        "question": "What is the definition of 'enthalpy' in thermodynamics?",
        "options": [
            "The total energy of the system including both internal energy and the work done by the system.",
            "The energy stored in the system due to its temperature.",
            "The energy associated with phase changes in the system.",
            "The energy required to break intermolecular bonds in a substance."
        ],
        "answer": "The total energy of the system including both internal energy and the work done by the system."
    },
    {
        "question": "In thermodynamics, what is the 'Gibbs free energy' used to determine?",
        "options": [
            "The work done by a system during an adiabatic process.",
            "The spontaneity of a thermodynamic process.",
            "The temperature at which a phase change occurs.",
            "The total energy of the system."
        ],
        "answer": "The spontaneity of a thermodynamic process."
    },
    {
        "question": "What is the principle of the second law of thermodynamics?",
        "options": [
            "The total energy of an isolated system is constant.",
            "Entropy tends to increase in any spontaneous process.",
            "Energy can be fully converted from one form to another.",
            "Work and heat are mutually interchangeable."
        ],
        "answer": "Entropy tends to increase in any spontaneous process."
    },
    {
        "question": "In a heat engine, what does the 'thermal efficiency' measure?",
        "options": [
            "The proportion of heat energy converted to mechanical work.",
            "The amount of heat lost to the surroundings.",
            "The total energy supplied to the system.",
            "The total work done by the engine."
        ],
        "answer": "The proportion of heat energy converted to mechanical work."
    }
];
