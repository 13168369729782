export const Thermodynamics_set2 = [
    {
        "question": "What is the definition of enthalpy?",
        "options": [
            "The total energy of a system, including internal energy and the energy required to displace the environment.",
            "The energy required to raise the temperature of a unit mass of a substance by 1°C.",
            "The change in internal energy due to a change in volume at constant temperature.",
            "The ability of a system to do work on its surroundings."
        ],
        "answer": "The total energy of a system, including internal energy and the energy required to displace the environment."
    },
    {
        "question": "What does the third law of thermodynamics state?",
        "options": [
            "Entropy increases as energy is transferred.",
            "The entropy of a system approaches zero as the temperature approaches absolute zero.",
            "Energy can neither be created nor destroyed.",
            "The work done by a system is proportional to the temperature change."
        ],
        "answer": "The entropy of a system approaches zero as the temperature approaches absolute zero."
    },
    {
        "question": "Which of the following processes is considered reversible in thermodynamics?",
        "options": [
            "Free expansion of a gas",
            "Adiabatic expansion of an ideal gas",
            "Irreversible mixing of gases",
            "Combustion of fuel"
        ],
        "answer": "Adiabatic expansion of an ideal gas"
    },
    {
        "question": "What is the primary factor that determines the efficiency of a heat engine?",
        "options": [
            "The rate at which energy is converted into work",
            "The pressure of the working substance",
            "The temperature difference between the heat source and sink",
            "The type of fuel used"
        ],
        "answer": "The temperature difference between the heat source and sink"
    },
    {
        "question": "Which of the following is a characteristic of an isochoric process?",
        "options": [
            "The temperature of the system remains constant.",
            "The volume of the system remains constant.",
            "The pressure of the system remains constant.",
            "The entropy of the system remains constant."
        ],
        "answer": "The volume of the system remains constant."
    },
    {
        "question": "What is the relationship between heat and work in an adiabatic process?",
        "options": [
            "Heat is equal to work done by the system.",
            "Heat is zero, and all energy changes are in the form of work.",
            "Heat is lost to the surroundings.",
            "Heat equals the change in internal energy."
        ],
        "answer": "Heat is zero, and all energy changes are in the form of work."
    },
    {
        "question": "What is the significance of the Maxwell relations in thermodynamics?",
        "options": [
            "They describe the relationship between different thermodynamic potentials and state variables.",
            "They define the work done by a system at constant pressure.",
            "They give the formula for calculating entropy change.",
            "They provide a method for calculating the equilibrium temperature."
        ],
        "answer": "They describe the relationship between different thermodynamic potentials and state variables."
    },
    {
        "question": "In thermodynamics, what is the term for the energy required to raise the temperature of a substance?",
        "options": [
            "Specific heat capacity",
            "Latent heat",
            "Enthalpy",
            "Internal energy"
        ],
        "answer": "Specific heat capacity"
    },
    {
        "question": "Which of the following is a type of thermodynamic cycle?",
        "options": [
            "Carnot cycle",
            "Frictional cycle",
            "Convection cycle",
            "Elastic cycle"
        ],
        "answer": "Carnot cycle"
    },
    {
        "question": "What is the effect of increasing the volume of a gas at constant temperature (Boyle's Law)?",
        "options": [
            "The pressure increases.",
            "The pressure decreases.",
            "The temperature increases.",
            "The temperature decreases."
        ],
        "answer": "The pressure decreases."
    }
];
